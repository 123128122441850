import firebase from 'firebase/app';

// Add products to use
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

const developConfig = {
  apiKey: 'AIzaSyB9Mj1J9mrQYJ0rT9wv9KP90iH4BcqMgsM',
  authDomain: 'atcoder-anytime-dev.firebaseapp.com',
  databaseURL: 'https://atcoder-anytime-dev.firebaseio.com',
  projectId: 'atcoder-anytime-dev',
  storageBucket: 'atcoder-anytime-dev.appspot.com',
  messagingSenderId: '101909187422',
  appId: '1:101909187422:web:584a3ec2a80b5adc211dcf',
};

const productionConfig = {
  apiKey: 'AIzaSyAspLb2bxIy8c0-Lyw9E0BZZOHI5lzRPnA',
  authDomain: 'atcoder-anytime-asia.firebaseapp.com',
  databaseURL: 'https://atcoder-anytime-asia.firebaseio.com',
  projectId: 'atcoder-anytime-asia',
  storageBucket: 'atcoder-anytime-asia.appspot.com',
  messagingSenderId: '505229176475',
  appId: '1:505229176475:web:490edc0041a137d0a5203e',
  measurementId: 'G-B06GR33PFY',
};

const firebaseConfig =
  process.env.REACT_APP_ENV === 'production' ? productionConfig : developConfig;

firebase.initializeApp(firebaseConfig);

if (process.env.REACT_APP_ENV !== 'production') {
  // firebase.functions().useFunctionsEmulator('http://localhost:5000');
}

export default firebase;
